import { GraphQLClient } from "graphql-request"
import { RequestMiddleware } from "graphql-request/build/esm/types"
import {
  FLAG_DIET_COOKIE,
  TRequest,
  graphqlClientMiddleware,
  graphqlClientMiddlewareV2,
} from "shared-utils"

export const GRAPHQL_URL = FLAG_DIET_COOKIE
  ? `/graphql`
  : process.env.NEXT_PUBLIC_FEDERATED_TYK_URL ||
    "https://gtp-tyk.eproc.dev/graphql/"

const middleware: RequestMiddleware = (request) => {
  const resultMiddleware = FLAG_DIET_COOKIE
    ? graphqlClientMiddlewareV2({
        request: request as TRequest,
        signOut: () => {
          return
        },
      })
    : graphqlClientMiddleware({
        request: request as TRequest,
        signOut: () => {
          return
        },
      })
  return resultMiddleware
}

export const federatedGqlClient = new GraphQLClient(GRAPHQL_URL, {
  requestMiddleware: middleware,
  credentials: "include",
})
